export const INACTIVE_ERROR_MESSAGE =
  'Sorry, we are experiencing some technical difficulties. Please try again later.';

export const NO_BOUGHT_MS =
  'It looks like there are no bought items to show at this moment, please check again later';

export const NO_SOLD_MS =
  'It looks like there are no sold items to show at this moment, please check again later';

// export const NO_ARCHIVED_MS =
//   'It looks like there are no archived items to show at this moment, please check again later';

export const NO_LISTINGS_MS =
  'It looks like there are no listings to show at this moment, please check again later.';

export const NO_PROJECT_NAME_MS =
  'It looks like there is no project' +
  ' with this name to show at this moment, please check again later.';

export const NO_PROJECTS_MS =
  'It looks like there is no projects ' +
  ' show at this moment, please check again later.';

export const NO_ALLOCATIONS_MS =
  'It looks like there are no allocations to show at this moment, please check again later.';
export const NO_OFFERS_MS =
  'It looks like there are no offers to show at this moment, please check again later.';

export const NO_PAGE_MS =
  'It looks like there is no page with this name to show at this moment, please check again later.';

export const NO_TRANSACTIONS_MS =
  'It looks like there are no transactions to show at this moment, please check again later';

export const NO_INVESTMENT_MS =
  'It looks like there are no invested items to show at this moment, please check again later';
