import IsLoadingCom from '@components/shared/IsLoadingCom';
import Default from '@pages/Default';
import { useGetCurrentUserQuery } from '@store/api/userApi';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

interface Props {
  children: JSX.Element;
}

function AuthGuard({ children }: Props) {
  const { data: user, isLoading, isFetching } = useGetCurrentUserQuery({});
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);

  useEffect(() => {
    if (!user && !isLoading && !isFetching) {
      navigate('/');
    }
  }, [isFetching, isLoading, navigate, user]);

  if (isLoading || isFetching) {
    return <IsLoadingCom />;
  }

  if (user && !user.isWhitelisted) {
    return (
      <Default
        fullHeight
        text="You are not authorized to make actions on the platform"
      >
        <Button>Logout</Button>
      </Default>
    );
  }

  return children;
}

export default AuthGuard;
