/* eslint-disable import/no-unresolved */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Collections } from '@constants/CCollections';
import Moralis from 'moralis-v1';
import { useToggle, useEffectOnce } from 'react-use';
import IsLoadingCom from '@components/shared/IsLoadingCom';
import { Box } from '@mui/material';
import Default from '@pages/Default';
import { INACTIVE_ERROR_MESSAGE } from '@constants/CDefaultMessages';
import { useAppDispatch } from '@store/store';
import { listingApi } from '@store/api/listingApi';
import { ApiTag } from '@typescript/Api.enum';
import { allocationApi } from '@store/api/allocationApi';
import { offerApi } from '@store/api/offerApi';
import { projectDetailApi } from '@store/api/projectDetailApi';
import { projectApi } from '@store/api/projectApi';
import { transactionInvestmentApi } from '@store/api/transactionInvestmentApi';
import { userApi } from '@store/api/userApi';
import useUser from '@hooks/useUser';
import useNotification from '@hooks/useNotification';
import { transactionApi } from '@store/api/transactionApi';
import { ENV } from '@utils/myEnv';
import { whitelistsApi } from '@store/api/whitelistsApi';

interface Props {
  children: React.ReactNode;
}

function ClassSubscriptions({ children }: Props) {
  const dispatch = useAppDispatch();

  const { notifySuccess } = useNotification();

  const [isLoading, toggleIsLoading] = useToggle(false);

  const [serverOffline, toggleServerOffline] = useToggle(false);

  const subscribeToAllClasses = async () => {
    toggleIsLoading(true);
    try {
      const whitelistsSubscription = await new Moralis.Query(
        Collections.WHITELISTS,
      ).subscribe();
      const allocationSubscription = await new Moralis.Query(
        Collections.ALLOCATIONS,
      ).subscribe();
      const projectSubscription = await new Moralis.Query(
        Collections.PROJECTS,
      ).subscribe();
      const projectDetailsSubscription = await new Moralis.Query(
        Collections.PROJECTS_DETAILS,
      ).subscribe();
      const offerSubscription = await new Moralis.Query(
        Collections.OFFERS,
      ).subscribe();
      const listingsSubscription = await new Moralis.Query(
        Collections.LISTINGS,
      ).subscribe();
      const feeSubscription = await new Moralis.Query(
        Collections.FEES,
      ).subscribe();
      const txInvestmentSubscription = await new Moralis.Query(
        Collections.TRANSACTIONS_INVESTMENT,
      ).subscribe();
      const tradeTxSubscription = await new Moralis.Query(
        Collections.TRADE_TRANSACTIONS,
      ).subscribe();
      const userSubscription = await new Moralis.Query(
        Collections.USERS,
      ).subscribe();
      const userInfoSubscription = await new Moralis.Query(
        Collections.USERS_INFORMATIONS,
      ).subscribe();
      const tokenDistributionsSubscription = await new Moralis.Query(
        Collections.TOKEN_DISTRIBUTIONS,
      ).subscribe();

      const whitelistsActions = () => {
        dispatch(whitelistsApi.util.invalidateTags([ApiTag.WHITELISTS]));
      };
      const allocationActions = () => {
        dispatch(allocationApi.util.invalidateTags([ApiTag.ALLOCATIONS]));
      };

      const projectActions = () => {
        dispatch(projectApi.util.invalidateTags([ApiTag.PROJECTS]));
        dispatch(listingApi.util.invalidateTags([ApiTag.LISTINGS]));
        dispatch(allocationApi.util.invalidateTags([ApiTag.ALLOCATIONS]));
      };

      const offerActions = () => {
        dispatch(listingApi.util.invalidateTags([ApiTag.LISTINGS]));
        dispatch(offerApi.util.invalidateTags([ApiTag.OFFERS]));
      };

      const listingActions = () => {
        dispatch(offerApi.util.invalidateTags([ApiTag.OFFERS]));
        dispatch(listingApi.util.invalidateTags([ApiTag.LISTINGS]));
      };

      const investmentActions = () => {
        dispatch(
          transactionInvestmentApi.util.invalidateTags([
            ApiTag.TRANSACTIONS_INVESTMENT,
          ]),
        );
      };

      allocationSubscription.on('open', () => {
        toggleServerOffline(false);
      });
      if (!serverOffline) {
        // eslint-disable-next-line max-len
        // TODO:handle error message for breaking the websocket, enters a loop where Moralis tries to reconnect and closes
        allocationSubscription.on('close', () => {
          // localStorage.clear();
          // projectSubscription.unsubscribe();
          toggleServerOffline(true);
        });
      }

      // ? Whitelists events
      whitelistsSubscription.on('create', () => {
        whitelistsActions();
      });
      whitelistsSubscription.on('delete', () => {
        whitelistsActions();
      });

      // ? Allocation events
      allocationSubscription.on('create', () => {
        allocationActions();
      });
      allocationSubscription.on('delete', () => {
        allocationActions();
      });
      allocationSubscription.on('update', () => {
        allocationActions();
      });

      // ? Project events
      projectSubscription.on('create', () => {
        projectActions();
      });
      projectSubscription.on('delete', () => {
        projectActions();
      });
      projectSubscription.on('update', () => {
        projectActions();
      });

      // ? Project Details events
      projectDetailsSubscription.on('create', () => {
        dispatch(projectDetailApi.util.invalidateTags([ApiTag.PROJECT_DETAIL]));
      });
      projectDetailsSubscription.on('delete', () => {
        dispatch(projectDetailApi.util.invalidateTags([ApiTag.PROJECT_DETAIL]));
      });
      projectDetailsSubscription.on('update', () => {
        dispatch(projectDetailApi.util.invalidateTags([ApiTag.PROJECT_DETAIL]));
      });

      // ? Offer events
      offerSubscription.on('create', () => {
        offerActions();
      });
      offerSubscription.on('delete', () => {
        offerActions();
      });
      offerSubscription.on('update', () => {
        offerActions();
      });

      // ? Listings events
      listingsSubscription.on('create', () => {
        listingActions();
      });
      listingsSubscription.on('update', () => {
        listingActions();
      });
      listingsSubscription.on('delete', () => {
        listingActions();
      });

      // ? Fee events
      feeSubscription.on('create', () => {
        dispatch(projectApi.util.invalidateTags([ApiTag.FEE]));
      });
      feeSubscription.on('delete', () => {
        dispatch(projectApi.util.invalidateTags([ApiTag.FEE]));
      });
      feeSubscription.on('update', () => {
        dispatch(projectApi.util.invalidateTags([ApiTag.FEE]));
      });

      // ? Investment events
      txInvestmentSubscription.on('create', () => {
        investmentActions();
      });

      // ? Transaction events
      tradeTxSubscription.on('create', (tx) => {
        const userFromLocalStorage = localStorage.getItem(
          `Parse/${ENV.serverId}/currentUser`,
        );
        const user = userFromLocalStorage
          ? JSON.parse(userFromLocalStorage)
          : null;

        const to = tx.attributes.to.id;
        const from = tx.attributes.from.id;
        const shouldNotify = to === user?.objectId || from === user?.objectId;

        if (shouldNotify) {
          notifySuccess('Transaction created!');
          dispatch(userApi.util.invalidateTags([ApiTag.USER]));
          dispatch(transactionApi.util.invalidateTags([ApiTag.TRANSACTIONS]));
        }
      });

      tradeTxSubscription.on('update', (tx) => {
        const userFromLocalStorage = localStorage.getItem(
          `Parse/${ENV.serverId}/currentUser`,
        );
        const user = userFromLocalStorage
          ? JSON.parse(userFromLocalStorage)
          : null;

        const to = tx.attributes.to.id;
        const from = tx.attributes.from.id;
        const shouldNotify = to === user?.objectId || from === user?.objectId;

        if (shouldNotify && tx.attributes?.confirmed) {
          notifySuccess('Transaction confirmed!');
          dispatch(userApi.util.invalidateTags([ApiTag.USER]));
          dispatch(transactionApi.util.invalidateTags([ApiTag.TRANSACTIONS]));
        }
      });

      // ? User events
      userSubscription.on('update', () => {
        dispatch(userApi.util.invalidateTags([ApiTag.USER]));
      });

      userInfoSubscription.on('update', () => {
        dispatch(userApi.util.invalidateTags([ApiTag.USER]));
      });

      // ? Token Distribution events
      tokenDistributionsSubscription.on('create', () => {
        dispatch(projectApi.util.invalidateTags([ApiTag.TOKEN_DISTRIBUTIONS]));
      });
      tokenDistributionsSubscription.on('delete', () => {
        dispatch(projectApi.util.invalidateTags([ApiTag.TOKEN_DISTRIBUTIONS]));
      });
      tokenDistributionsSubscription.on('update', () => {
        dispatch(projectApi.util.invalidateTags([ApiTag.TOKEN_DISTRIBUTIONS]));
      });
    } finally {
      toggleIsLoading(false);
    }
  };

  useEffectOnce(() => {
    subscribeToAllClasses();
  });

  if (isLoading) {
    return <IsLoadingCom />;
  }

  if (serverOffline) {
    return (
      <Box sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
        <Default fullHeight text={INACTIVE_ERROR_MESSAGE} />
      </Box>
    );
  }

  return <Box>{children}</Box>;
}

export default ClassSubscriptions;
