import { Coin } from '@constants/CCoin';
import { MAX_TRADING_PRICE, MIN_TRADING_PRICE } from '@constants/CLimits';
import { calculate, formatNr } from '@utils/functions';
import * as yup from 'yup';

export const WTSListingValidationSchema = (
  currentTokenPrice: number,
  lockedTokens: number,
) => {
  const MAX_PERCENTAGE_LIMIT = 1000; // ! 1000%;
  const MIN_PERCENTAGE_LIMIT = 90; // ! 90%;

  const maxTokenPrice = calculate(
    currentTokenPrice,
    '*',
    MAX_PERCENTAGE_LIMIT / 100,
  );

  let minTokenPrice = calculate(
    currentTokenPrice,
    '*',
    MIN_PERCENTAGE_LIMIT / 100,
  );
  minTokenPrice = calculate(currentTokenPrice, '-', minTokenPrice);

  return yup.object().shape({
    tokenPrice: yup
      .number()
      .positive('Token price must be greater than 0.')
      .min(
        minTokenPrice,
        `Minimum token price is ${formatNr(
          minTokenPrice,
          true,
        )} (-${MIN_PERCENTAGE_LIMIT}%)`,
      )
      .max(
        maxTokenPrice,
        `Maximum token price is ${formatNr(maxTokenPrice, true)} (10x)`,
      )
      .required('Token price is required!'),
    tokenAmount: yup
      .number()
      .positive('Token amount must be greater than 0.')
      .max(lockedTokens, `Your maximum amount is: ${lockedTokens}`)
      .required('Token amount is required!'),
    totalCost: yup
      .number()
      .positive('Total cost must be greater than 0.')
      .min(MIN_TRADING_PRICE, `Minimum OTC total cost is ${MIN_TRADING_PRICE}$`)
      .max(MAX_TRADING_PRICE, `Maximum OTC total cost is ${MAX_TRADING_PRICE}$`)
      .required('Total cost is required!'),
    coins: yup
      .array()
      .min(1, 'One coin is required!')
      .required('One coin is required!'),
    expireIn: yup.string().min(1, 'Minimum 1 day').required('Required!'),
    acceptTerms: yup.boolean().isTrue('You must agree to our Terms!'),
    discordName: yup.string(),
    telegramLink: yup.string(),
  });
};

export const WTBListingValidationSchema = (
  hightestLockedTokens: number,
  userHightestBalance: number,
) => {
  const maxBuyingPrice =
    userHightestBalance > MAX_TRADING_PRICE
      ? MAX_TRADING_PRICE
      : userHightestBalance;

  const maxBuyingPriceMessage =
    userHightestBalance > MAX_TRADING_PRICE
      ? `Maximum OTC total cost is ${formatNr(MAX_TRADING_PRICE, true)}`
      : `Your maximum balance is ${formatNr(userHightestBalance, true)}`;

  return yup.object().shape({
    tokenPrice: yup
      .number()
      .positive('Token price must be greater than 0.')
      .required('Token price is required!'),
    tokenAmount: yup
      .number()
      .positive('Amount must be greater than 0.')
      .max(
        hightestLockedTokens,
        `Maximum amount is ${formatNr(hightestLockedTokens)}.`,
      )
      .required('Token amount is required!'),
    totalCost: yup
      .number()
      .positive('The buying price must be greater than 0.')
      .min(MIN_TRADING_PRICE, `Minimum OTC total cost is ${MIN_TRADING_PRICE}$`)
      .max(maxBuyingPrice, maxBuyingPriceMessage)
      .required('Total cost is required!'),
    coins: yup
      .array()
      .min(1, 'One coin is required!')
      .required('One coin is required!'),
    acceptTerms: yup.boolean().isTrue('You must agree to our Terms!'),
    discordName: yup.string(),
    telegramLink: yup.string(),
  });
};
export const UpdateListingExpirationSchema = () => {
  return yup.object().shape({
    expireIn: yup
      .string()
      .min(1, 'Minimum 1 day')
      .max(30, 'Maximum 30 days')
      .required('Required!'),
  });
};

export const BuyConfirmationValidationSchema = (listingPrice: number) =>
  yup.object().shape({
    selectedCoin: yup.string().required('You have to select one coin'),
    coinValue: yup.number().min(listingPrice, 'Insufficient funds'),
  });

export const MakeOfferValidation = (listingAskingPrice: number) => {
  const MIN_OFFER_PERCENTAGE = 10;

  const minimumPrice = calculate(listingAskingPrice, '/', MIN_OFFER_PERCENTAGE);

  return yup.object().shape({
    selectedCoin: yup
      .string()
      .required('You have to select at least one coin.'),
    offeredTokenPrice: yup
      .number()
      .positive('Offer price must be greater than 0!')
      .min(
        minimumPrice,
        `Offer must be at least 10% of asking price. (${formatNr(
          minimumPrice,
          true,
        )})`,
      )
      .lessThan(
        listingAskingPrice,
        'Offer price must be less than the asking price.',
      )
      .required('Required.'),
    offeredPrice: yup
      .number()
      .positive('Total cost must ge greater than 0')
      .min(MIN_TRADING_PRICE, `Minimum OTC total cost is ${MIN_TRADING_PRICE}$`)
      .max(
        MAX_TRADING_PRICE,
        `Maximum OTC offer price is ${formatNr(MAX_TRADING_PRICE, true)}`,
      ),
  });
};

export const SellConfirmationValidationSchema = (listingAmount: number) =>
  yup.object().shape({
    amount: yup
      .number()
      .min(listingAmount, 'You do not have enough allocation amount!'),
    selectedCoin: yup
      .string()
      .required('You have to select at least one coin.'),
  });

const calculateMaximumInvestment = (
  maxInvestment: number,
  userHightestBalance: number,
  investmentAmountRemaining: number,
) => {
  const firstInvestmentLimit = Math.min(
    maxInvestment,
    investmentAmountRemaining,
    userHightestBalance,
  );

  switch (true) {
    case firstInvestmentLimit === maxInvestment:
      return {
        investment: maxInvestment,
        msg: `The maximum investment per user is ${formatNr(
          maxInvestment,
          true,
        )}.`,
      };

    case firstInvestmentLimit === investmentAmountRemaining:
      return {
        investment: investmentAmountRemaining,
        msg: `The remaining investment is ${formatNr(
          investmentAmountRemaining,
          true,
        )}.`,
      };

    case firstInvestmentLimit === userHightestBalance:
      return {
        investment: userHightestBalance,
        msg: `Your maximum balance is ${formatNr(userHightestBalance, true)}.`,
      };
    default: {
      throw Error('calculateMaximumInvestment invalid limit found.');
    }
  }
};

export const WTIListingValidationSchema = (
  maxInvestment: number,
  minInvestment: number,
  userHightestBalance: number,
  investmentAmountRemaining: number,
) => {
  const { investment, msg } = calculateMaximumInvestment(
    maxInvestment,
    userHightestBalance,
    investmentAmountRemaining,
  );

  return yup.object().shape({
    totalCost: yup
      .number()
      .positive('The buying price must be greater than 0.')
      .min(
        minInvestment,
        `The minimum investment must be at least ${formatNr(
          minInvestment,
          true,
        )}.`,
      )
      .max(investment, msg)
      .required('Total cost is required!'),
    coin: yup
      .mixed<keyof typeof Coin>()
      .oneOf(Object.values(Coin))
      .required('One coin is required!'),
    acceptTerms: yup.boolean().isTrue('You must agree to our Terms!'),
  });
};
