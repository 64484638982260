export enum Collections {
  ROLES = '_Role',
  SESSIONS = '_Session',
  USERS = '_User',
  USERS_INFORMATIONS = 'UsersInformations',
  LISTINGS = 'Listings',
  TRADE_TRANSACTIONS = 'TradeTransactions',
  PROJECTS = 'Projects',
  PROJECTS_DETAILS = 'ProjectsDetails',
  TOKEN_DISTRIBUTIONS = 'TokenDistributions',
  ALLOCATIONS = 'Allocations',
  OFFERS = 'Offers',
  FEES = 'Fees',
  TRANSACTIONS_INVESTMENT = 'TransactionsInvestment',
  WHITELISTS = 'Whitelists',
}
