import { Coin } from '@constants/CCoin';
import { TListing } from '@typescript/models/Listing.model';
import {
  TBuyConfirmationInterface,
  TCreateWTBListingInitialValues,
  TCreateWTIListingInitialValues,
  TCreateWTSListingInitialValues,
  TMakeOfferInitialValues,
  TSellConfirmationInterface,
  TUpdateListingExpirationInitialValues,
} from '@typescript/TModalFormik';

export const BuyConfirmationValues: TBuyConfirmationInterface = {
  selectedCoin: '',
  coinValue: 0,
};

export const MakeOfferInitialValues: TMakeOfferInitialValues = {
  selectedCoin: '',
  offeredPrice: '',
  offeredTokenPrice: '',
  coinValue: 0,
};

export const WTSListingInitialValues: TCreateWTSListingInitialValues = {
  tokenPrice: '',
  tokenAmount: '',
  totalCost: '',
  coins: [Coin.USDT],
  expireIn: 1,
  negotiable: true,
  acceptTerms: false,
  discordName: '',
  telegramLink: '',
};

export const WTBListingInitialValues: TCreateWTBListingInitialValues = {
  tokenPrice: '',
  tokenAmount: '',
  totalCost: '',
  coins: [Coin.USDT],
  expireIn: 1,
  acceptTerms: false,
  discordName: '',
  telegramLink: '',
};

export const WTIListingInitialValues: TCreateWTIListingInitialValues = {
  tokenAmount: '',
  totalCost: '',
  coin: Coin.USDT,
  acceptTerms: false,
};

export const SellConfirmationValues: TSellConfirmationInterface = {
  amount: 0,
  selectedCoin: '',
  coinValue: 0,
};

export const ModifyWTSListingInitialValues = (
  listing: TListing,
): TCreateWTSListingInitialValues => ({
  tokenPrice: listing.tokenPrice.toString(),
  tokenAmount: listing.amount.toString(),
  totalCost: listing.price.toString(),
  coins: listing.coins,
  expireIn: listing.expireIn,
  negotiable: listing.negotiable,
  acceptTerms: true,
  discordName: listing.discordName || '',
  telegramLink: listing.telegramLink || '',
});

export const UpdateListingExpirationInitialValues = (
  listing: TListing,
): TUpdateListingExpirationInitialValues => ({
  expireIn: listing.expireIn || 1,
});

export const ModifyWTBListingInitialValues = (
  listing: TListing,
): TCreateWTBListingInitialValues => ({
  tokenPrice: listing.tokenPrice.toString(),
  tokenAmount: listing.amount.toString(),
  totalCost: listing.price.toString(),
  coins: listing.coins,
  expireIn: listing.expireIn,
  acceptTerms: true,
  discordName: listing.discordName || '',
  telegramLink: listing.telegramLink || '',
});
